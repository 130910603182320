<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">CALL-Центр</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Информация
          </div>
          <div class="card-body fs-13">
            <p><b>Звонки:</b> Сегодня: {{ callToday }} (<span class="green">{{ callSuccessToday }}</span>/<span
                class="text-danger">{{ callFailedToday }}</span>) В этом месяце: {{ callMonth }} (<span
                class="green">{{ callSuccessMonth }}</span>/<span class="text-danger">{{ callFailedMonth }}</span>)</p>
            <p><b>Заказы:</b> Сегодня: <span class="blue">{{ awardToday.length }}</span> / В этом месяце: <span
                class="blue">{{ awardMonth.length }}</span></p>
            <p><b>Сумма заказов:</b> Сегодня: <span class="blue">{{ amountOrderToday }}</span> руб. / В этом месяце: <span
                class="blue">{{ amountOrderMonth }}</span> руб.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-filter mb-3 fs-12" @keyup.enter="startFilter">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Номер страницы</label>
            <input type="number" class="form-control" placeholder="Номер страницы" v-model.number="filter.currentPage"
                   :min="1" :max="Math.ceil(totalCountList / 12)">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Поиск
              <vue-custom-tooltip label="Поиск по ФИО или телефону"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" placeholder="Поиск" v-model="filter.search">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Кол-во заказов от</label>
            <input type="text" class="form-control" placeholder="Кол-во заказов от" v-model="filter.orderCountBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Кол-во заказов до</label>
            <input type="text" class="form-control" placeholder="Кол-во заказов до" v-model="filter.orderCountAfter">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Последний заказ от</label>
            <input type="date" class="form-control" placeholder="От" v-model="filter.lastOrderBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Последний заказ до</label>
            <input type="date" class="form-control" placeholder="До" v-model="filter.lastOrderAfter">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Средний чек от</label>
            <input type="text" class="form-control" placeholder="От" v-model="filter.avgCheckBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Средний чек до</label>
            <input type="text" class="form-control" placeholder="До" v-model="filter.avgCheckAfter">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <div class="form-check form-switch fs-16">
            <input class="form-check-input" type="checkbox" role="switch" id="switch-called" v-model="filter.called" @change="startFilter">
            <label class="form-check-label" for="switch-called">Скрыть кому звонили</label>
          </div>
        </div>

      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group mt-2">
          <button type="button" class="btn btn-secondary" @click="refreshFilter">Сбросить фильтр</button>
          <button type="button" class="btn btn-success" @click="startFilter">Найти</button>
        </div>
      </div>
    </div>
    <div class="table-header">Клиентов ({{ totalCountList }})</div>
    <table class="table table-hover table-bordered fs-12">
      <thead>
      <tr>
        <th scope="col">ФИО</th>
        <th scope="col">Телефон</th>
        <th scope="col">Адрес</th>
        <th scope="col">Количество заказов</th>
        <th scope="col">Средний чек</th>
        <th scope="col">Последний заказ</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in callList" :key="item.id">
        <td>
          <a class="pointer" @click="checkVisit(item.id)">{{
              item.full_name.length > 0 ? item.full_name : 'Нет данных'
            }}</a>
        </td>
        <td>
          <a class="pointer" @click="checkVisit(item.id)">{{ item.phone }} </a>
        </td>
        <td>{{ item.last_order_address.length > 0 ? item.last_order_address : 'Нет данных' }}</td>
        <td v-if="item.total_order_count">{{ parseInt(item.total_order_count) }}</td>
        <td v-else>0</td>
        <td v-if="item.avg_check">{{ parseFloat(item.avg_check).toFixed(2) }} руб.</td>
        <td v-else>0.00 руб.</td>
        <td v-if="item.last_order_date">{{ item.last_order_date }}
          <br>
          <span class="fs-12">Дней назад: {{ calculateLastOrder(item.last_order_date) }}</span>
        </td>
        <td v-else>Заказов не было</td>
        <td class="actions">
          <a class="pointer" @click="checkVisit(item.id)">
            <i class="fas fa-phone-alt"></i>
          </a>
          <a @click="recallTomorrow(item.id)">
            <vue-custom-tooltip label="Перезвонить завтра"><i class="fas fa-history"></i>
            </vue-custom-tooltip>
          </a>
          <router-link :to="'/view-user/' + item.id" target="_blank">
            <i class="fas fa-pen"></i>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="d-flex justify-content-center">
        <sliding-pagination
            :current="this.filter.currentPage"
            :total="Math.ceil(totalCountList / 12)"
            @page-change="pageChangeHandler"
        ></sliding-pagination>
      </div>
    </div>
    <view-call :id="selectUser" v-show="this.$store.state.modal.viewCall"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SlidingPagination from 'vue-sliding-pagination'
import ViewCall from "../../components/modal/call/view-call";

export default {
  name: 'call-info',
  components: {ViewCall, SlidingPagination},
  data: function () {
    return {
      filter: {
        currentPage: 1,
        limit: 12,
        search: '',
        orderCountBefore: 0,
        orderCountAfter: 100000,
        avgCheckBefore: 0,
        avgCheckAfter: 100000,
        lastOrderBefore: this.getAfterDate(),
        lastOrderAfter: this.getBeforeDate(),
        called: false,
      },
      settings: {
        ext: 0,
      },
      selectUser: 0,
    }
  },
  computed: {
    ...mapGetters(['callList', 'totalCountList', 'callToday', 'callMonth', 'awardToday', 'awardMonth', 'callSuccessToday', 'callSuccessMonth', 'callFailedToday', 'callFailedMonth', 'amountOrderToday', 'amountOrderMonth']),
  },
  async mounted() {
    this.getCallList();
    this.$root.$on('updateCallCenterList', () => {
      this.getCallList();
    });
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.filter.currentPage = selectedPage;
      this.getCallList();
    },
    getAfterDate: function () {
      var dateOffset = (24 * 60 * 60 * 1000) * 3500;
      var timestamp = new Date();
      var todayDate = new Date(timestamp - dateOffset).toISOString().slice(0, 10);
      return todayDate;
    },
    getBeforeDate: function () {
      var todayDate = new Date().toISOString().slice(0, 10);
      return todayDate;
    },
    calculateLastOrder: function (date) {
      if (date) {
        var date1 = new Date(date);
        var date2 = new Date();
        var daysLag = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
        return daysLag;
      }
    },
    checkVisit: function (id) {
      this.selectUser = id;
      this.$store.dispatch('checkVisitCall', id);
    },
    recallTomorrow: function (id) {
      this.$store.dispatch('recall', id).then(() => {
        this.getCallList();
      });
    },
    refreshFilter: function () {
      this.filter.search = '';
      this.filter.avgCheckBefore = 0;
      this.filter.avgCheckAfter = 100000;
      this.filter.lastOrderBefore = this.getAfterDate();
      this.filter.lastOrderAfter = this.getBeforeDate();
      this.filter.orderCountBefore = 0;
      this.filter.orderCountAfter = 100000;
      this.filter.currentPage = 1;
      this.getCallList();
    },
    startFilter: function () {
      if (this.filter.search) {
        this.filter.currentPage = 1;
      }
      this.getCallList();
    },
    getCallList: function () {
      this.$store.dispatch('getCallList', this.filter);
    },
  },
}
</script>